.questionsBox{
    width: 600px;
    max-width: 600px;
    height: fit-content;
}

.category{
    background: rgb(228, 228, 228);
    color: black;
    padding: .2rem 1rem .2rem .2rem;
    gap: .7rem;
    border-radius: .9rem;
    justify-content: flex-start;
    font-size: .75rem !important;
}

.category div{
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: white;
}
.questions{
    animation: slide .3s linear;
    transition: opacity .3s ease-in;
}

@keyframes slide {
    from{transform: translateX(4rem);opacity: 0;}
    to{transform: translateX(0);opacity: 1;}
}

.disable-copy-paste {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
    
.record-vid-btn,.rec-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.record-vid-btn button{
    background-color: #1565C0;
    padding: 10px;
    margin: 10px;
    color: white;
    border-radius: 8px;
}
.recorded-videos{
    display: flex;
    flex-wrap: wrap ;
}

.single-rec-vid{
    display: flex;
    flex-direction: column;

}


video {
    border-radius: 10px;
  }
  .selected-video div{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
  }
  .selected-video video{
    height: 400px;
    width: 400px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .selected-video h2{
    font-weight: 600;
    padding: 10px;
  }
  .selected-video button {
    margin-top: 10px;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .selected-video button:hover {
    background-color: #0056b3;
  }
  
  .textfield-type textarea{
    border: 1px solid skyblue;
    padding: 10px;
    border-radius: 4px;
    width: 80%;
  }