@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

body{
  font-family: roboto !important;
}
a{
  color: black;
  text-decoration: none;
}
.green200{
  color: rgb(20, 196, 20);
}
.bold{
  font-weight: 500;
}
.App {
  text-align: center;
}
.flex{
  display: flex;
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-center{
  justify-content: center;
}
.align-center{
  align-items: center;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(185, 185, 185);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: gray;
}
