.startTestBox{
    max-height: 98% !important;
    overflow-y: auto !important;
}

.assessment-description {
  ol,
  ul {
    all: revert;
  }
}